<template>
  <div>
    <PageHeader>
      <template slot="right">
        <label class="d-inline-flex align-items-center">
          <b-form-checkbox
            v-model="toggleStatus"
            switch
            @change="onToggleIntervalFetch"
          >
            ดึงข้อมูลอัตโนมัติ (ทุกๆ
            {{ intervalFetch.intervalTime / 1000 }} วินาที)
          </b-form-checkbox>
        </label>
      </template>
    </PageHeader>
    <b-button-group
      v-if="
        filteredMenu.length > 0 &&
          currentRouteName !== 'VIEW_PLAYER_TRANSACTION_HISTORY'
      "
      class="mb-2"
    >
      <b-button
        v-for="prop in filteredMenu"
        :key="prop.name"
        :variant="currentRouteName === prop.name ? '' : 'light'"
        @click="navigateRoute(prop.path)"
      >
        {{ $t(`routes.${prop.name}`) }}
        <b-badge
          v-if="prop.name === 'VIEW_TRANSACTION_REQUEST'"
          variant="danger"
          class="float-right ml-2"
        >
          {{ requestsTotal }}
        </b-badge>
        <b-badge
          v-if="prop.name === 'VIEW_TRANSACTION_UNKNOWN_LOGS'"
          variant="danger"
          class="float-right ml-2"
        >
          {{ unMatchTotal }}
        </b-badge>
      </b-button>
    </b-button-group>
    <div>
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  data() {
    return {
      toggleStatus: true,
      menu: [
        {
          name: 'VIEW_TRANSACTION_REQUEST',
          path: '/transactions/requests',
        },
        {
          name: 'VIEW_TRANSACTION_HISTORY',
          path: '/transactions/history',
        },
        {
          name: 'VIEW_TRANSACTION_UNKNOWN_LOGS',
          path: '/transactions/unmatch',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['withdrawRequests', 'adminPermissions', 'unMatchHistory', 'isOwner', 'isMaster']),
    ...mapState({
      intervalFetch: (state) => state.credit.intervalFetch,
    }),
    filteredMenu() {
      return this.menu
    },
    currentRouteName() {
      return this.$route.name
    },
    requestsTotal() {
      const { data } = this.withdrawRequests
      return data?.length ? data.length : 0
    },
    unMatchTotal() {
      return this.unMatchHistory.data ? this.unMatchHistory.data.length : 0
    },
  },
  watch: {
    'intervalFetch.enabled'(val) {
      this.toggleStatus = val
    },
  },
  created() {
    if (this.adminPermissions.length > 0) {
      if (this.$route.path === '/transactions/requests') {
        this.$router.replace('/transactions/history')
      }
    }
    this.fetchData()
  },
  methods: {
    ...mapActions([
      'fetchWithdrawRequests',
      'fetchUnMatchHistory',
      'toggleIntervalFetch',
    ]),
    fetchData() {
      // this.fetchWithdrawRequests({
      //   limit: 50,
      //   offset: 0,
      // })
      // this.fetchUnMatchHistory({
      //   limit: 50,
      //   offset: 0,
      //   agentId:
      //     this.isOwner || this.isMaster
      //       ? null
      //       : this.userInfo.agentId,
      // })
    },
    onToggleIntervalFetch() {
      this.toggleIntervalFetch()
    },
    navigateRoute(path) {
      if (path !== this.$route.name) {
        this.$router.push(path)
      }
    },
  },
}
</script>
